<template>
  <div class="compliance-about">
    <h2 class="title">{{ $t("complianceTitle1") }}</h2>
    <div class="compliance-about__content">
      <div class="compliance-about__descr">
        <p>{{ $t("complianceText1") }}</p>
        <h3>{{ $t("complianceTitle2") }}</h3>
        <p class="compliance-small-text">{{ $t("complianceText2") }}</p>
      </div>
      <div class="compliance-about__img">
        <img src="@/assets/img/compliance/about.jpg" alt="image" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ComplianceAbout",
};
</script>
